import { useEffect, useReducer } from 'react';
import '../components/css/homescreen.css';
import '../components/css/index.css';
import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  Carousel,
  CarouselCaption,
  CarouselItem,
  Container,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { isBrowser } from 'react-device-detect';
import SeoUpdater from 'components/SeoUpdater';
import NewBees from 'components/NewBees';
import Reviews from 'components/Reviews';
import LoadingBox from 'components/LoadingBox';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import NavToShops from 'components/Homescreen/NavToShops';
import { usePageTracking } from 'utils/utils';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        products: action.payload,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const headerTags = {
  title: 'Willkommen | Nachhaltige Secondhand-Kinderkleidung',
  keywords:
    'Secondhand-Kinderkleidung, nachhaltige Mode, Spielzeugverleih, umweltfreundlich, BraunBärchen',
  description:
    'Entdecke BraunBärchen, deinen Onlineshop für nachhaltige Secondhand-Kinderkleidung und Spielzeugverleih. Gemeinsam für eine grünere Zukunft!',
  url: '/',
};

function HomeScreen() {
  //useLazyLoadCSS('../components/css/index.css');

  const navigate = useNavigate();
  usePageTracking();

  const [{ loading, products }, dispatch] = useReducer(reducer, {
    products: [],
    loading: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const { data } = await axios.get(`/api/products/newbees`);
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: err.message });
      }
    };
    fetchData();
  }, []);

  return (
    <div style={{ lineHeight: '1.6', position: 'relative', top: '-1rem' }}>
      <SeoUpdater headerTags={headerTags} />
      <Container
        fluid
        className="homescreen-content p-0"
        style={{ position: 'relative', overflow: 'hidden' }}
      >
        <Row className="mx-0 g-0" style={{ overflowX: 'hidden' }}>
          <Col className="w-100 p-0">
            <Carousel
              className="p-0 homescreen_carousel"
              controls={false}
              interval={5000000}
            >
              {/* <CarouselItem className="homescreen_carousel_item">
                <LazyLoadImage
                  alt="Kinder Secondhand Kleidung"
                  effect="blur"
                  src={`https://cdn.braunbaerchen.de/home/green-week-black-ween-black-friday-plant-a-tree-braunbaerchen-gruene-woche-baumpflanzen${
                    isBrowser || window.innerWidth > window.innerHeight
                      ? `.webp`
                      : `-mobile.webp`
                  }`}
                  style={{
                    width: '100vw',
                    maxWidth: '100%',
                    height: '60vh',
                    minHeight: '100%',
                    objectFit: 'cover',
                    backgroundPosition: 'center top',
                    objectPosition: 'top',
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage:
                      'radial-gradient(circle, rgba(0, 0, 0, 0.3) 25%, rgba(24, 24, 24, 0.5) 75%)',
                    zIndex: 1,
                  }}
                ></div>

                <Carousel.Caption
                  className="homescreen_carousel_caption homescreen_carousel_caption_green-week"
                  style={{ zIndex: 2 }}
                >
                  <div className="d-flex flex-column justify-content-between h-100">
                    <Stack gap={0}>
                      <h1>GREEN WEEK</h1>
                      <h2>statt</h2>
                      <h1>BLACK WEEK</h1>
                    </Stack>
                    <Stack gap={3}>
                      <h2 className="text-white">
                        Vom 23. - 29. November wird für jede Bestellung ein Baum
                        gepflanzt!
                      </h2>
                      <div
                        className="btn_action"
                        onClick={() => navigate('/shop/kleidung')}
                      >
                        Jetzt shoppen!
                      </div>
                    </Stack>
                  </div>
                </Carousel.Caption>
              </CarouselItem> */}
              {/* <CarouselItem className="homescreen_carousel_item">
                <LazyLoadImage
                  alt="Kinder Secondhand Kleidung"
                  effect="blur"
                  src={getCDNImage(
                    'https://braunbaerchenproductimages.s3.eu-central-1.amazonaws.com/home/advent-adventsaktion-startseite.webp'
                  )}
                  style={{
                    width: '100vw',
                    maxWidth: '100%',
                    height: '550px',
                    objectFit: 'cover',
                    backgroundPosition: 'left top',
                    objectPosition: 'left top',
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage:
                      'radial-gradient(circle, rgba(0, 0, 0, 0.3) 25%, rgba(24, 24, 24, 0.5) 75%)',
                    zIndex: 1,
                  }}
                ></div>

                <Carousel.Caption
                  className="homescreen_carousel_caption homescreen_carousel_caption_advent"
                  style={{ zIndex: 2 }}
                >
                  <Container
                    className="d-flex align-items-center p-0"
                    style={{ height: '475px' }}
                    fluid
                  >
                    <Stack
                      gap={3}
                      className={`align-items-center text-${
                        isBrowser ? 'center' : 'end'
                      } align-self-end`}
                    >
                      <h1 className="w-100">Adventsaktion</h1>
                      <p>
                        10% unseres Dezemberumsatzes* spenden wir an den
                        Förderverein der Wipperfürther Kita Don Bosco.
                      </p>
                      <div className={`d-flex align-items-center flex-column`}>
                        <DonBoscoLogo
                          height={isBrowser ? 100 : 70}
                          width={isBrowser ? 250 : 130}
                          style={{ width: 'auto' }}
                          className="mb-3"
                        />
                        <div>
                          <div
                            className="btn_action"
                            onClick={() => navigate('/shop/kleidung')}
                          >
                            Jetzt shoppen
                          </div>
                        </div>
                      </div>
                      <div
                        className="pt-1 w-100 text-end"
                        style={{ fontSize: isBrowser ? '0.5em' : '0.35em' }}
                      >
                        *Reiner Umsatz abzgl. Liefergebühren.
                      </div>
                    </Stack>
                  </Container>
                </Carousel.Caption>
              </CarouselItem>
              <CarouselItem className="homescreen_carousel_item homescreen_carousel_item_2">
                <LazyLoadImage
                  alt="Kinder Secondhand Kleidung"
                  effect="blur"
                  src={getCDNImage(
                    'https://braunbaerchenproductimages.s3.eu-central-1.amazonaws.com/home/advent-rabatt-weihnachtsbaeume-schneek-zipfelmuetze.webp'
                  )}
                  style={{
                    width: '100vw',
                    maxWidth: '100%',
                    height: '550px',
                    objectFit: 'cover',
                    backgroundPosition: 'bottom',
                    objectPosition: 'bottom',
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage:
                      'radial-gradient(circle, rgba(0, 0, 0, 0.3) 25%, rgba(24, 24, 24, 0.5) 75%)',
                    zIndex: 1,
                  }}
                ></div>

                <Carousel.Caption
                  className="homescreen_carousel_caption homescreen_carousel_caption_advent_rabatt"
                  style={{ zIndex: 2 }}
                >
                  <Container
                    className="d-flex align-items-center p-0"
                    style={{ height: '475px' }}
                    fluid
                  >
                    <Stack
                      gap={3}
                      className={`align-items-center text-${
                        isBrowser ? 'center' : 'end'
                      } align-self-end`}
                    >
                      <p className={`text-${isBrowser ? 'center' : 'start'}`}>
                      {isThirdAdvent ? 'Zum 4. Advent geben wir euch 20% Rabatt auf das gesamte Sortiment*' : 'Zum 2. Advent geben wir euch 15% Rabatt auf langärmelige Oberbekleidung, sowie Strick- und Sweatware.*' }
                      </p>
                      <div
                        className={`d-flex justify-content-${
                          isBrowser ? 'center' : 'start'
                        } mb-3`}
                      >
                        <div
                          className="btn_action"
                          onClick={() => navigate('/shop/kleidung?sale')}
                        >
                          Hier geht's zum Sale
                        </div>
                      </div>
                      <div
                        className="pt-1 w-100 text-end"
                        style={{ fontSize: isBrowser ? '0.5em' : '0.35em' }}
                      >*Aktion gilt vom {isThirdAdvent ? '22.12.24 bis 31.12.24' : '8.12.24 bis 14.12.24'}, gilt nicht für bereits rabattierte Kleidung
                      </div>
                    </Stack>
                  </Container>
                </Carousel.Caption>
              </CarouselItem> */}
              <CarouselItem className="homescreen_carousel_item">
                <LazyLoadImage
                  alt="Kinder Secondhand Kleidung"
                  effect="blur"
                  src={`https://cdn.braunbaerchen.de/home/kids-take-photo${
                    isBrowser || window.innerWidth > window.innerHeight
                      ? `.webp`
                      : `-mobile.webp`
                  }`}
                  style={{
                    width: '100vw',
                    maxWidth: '100%',
                    height: '550px',
                    objectFit: 'cover',
                    backgroundPosition: '50% 25%',
                    objectPosition: '50% 25%',
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage:
                      'radial-gradient(circle, rgba(0, 0, 0, 0.3) 25%, rgba(24, 24, 24, 0.5) 75%)',
                    zIndex: 1,
                  }}
                ></div>

                <Carousel.Caption
                  className="homescreen_carousel_caption homescreen_carousel_caption_1"
                  style={{ zIndex: 2 }}
                >
                  <Container
                    className="d-flex align-items-center p-0"
                    style={{ height: '475px' }}
                    fluid
                  >
                    <Stack gap={3} className="align-self-end">
                      Ausgewählte Kinder Secondhand Kleidung zu einem fairen
                      Preis in den größen 50 bis 152
                      <div
                        className="btn_action"
                        onClick={() => navigate('/shop/kleidung')}
                      >
                        Finde neue Lieblingsstücke
                      </div>
                    </Stack>
                  </Container>
                </Carousel.Caption>
              </CarouselItem>
              <CarouselItem className="homescreen_carousel_item homescreen_carousel_item_2">
                <CarouselCaption className="homescreen_carousel_caption homescreen_carousel_caption_2">
                  <Container
                    className="d-flex align-items-center p-0"
                    style={{ height: '475px' }}
                    fluid
                  >
                    <Stack gap={3} className="align-self-end">
                      Spare Zeit, Geld und shoppe nachhaltig
                      <Row className="w-100 d-flex justify-content-center">
                        <div
                          className="btn_action"
                          onClick={() => navigate('/shop/schlafsack')}
                        >
                          Hier geht's zu den Schlafsäcken
                        </div>
                      </Row>
                    </Stack>
                  </Container>
                </CarouselCaption>
              </CarouselItem>
              {/* <CarouselItem className="homescreen_carousel_item homescreen_carousel_item_3">
                <CarouselCaption className="homescreen_carousel_caption homescreen_carousel_caption_3">
                  <div className="w-100">
                    <Stack gap={3}>
                      Mieten statt kaufen spart nicht nur Geld
                      <br />
                      sondern auch Platz und schafft Abwechslung
                      <Row className="w-100 d-flex justify-content-start">
                        <div className="position-relative btn_action">
                          Jetzt Mietsachen entdecken
                          <span
                            className="coming-soon"
                            style={{ top: '-0.8em', right: '-1.5em' }}
                          >
                            Coming soon
                          </span>
                        </div>
                      </Row>
                    </Stack>
                  </div>
                </CarouselCaption>
              </CarouselItem> */}
            </Carousel>
          </Col>
        </Row>
        {loading ? (
          <LoadingBox />
        ) : (
          <>
            <Row className="pt-5">
              <Col
                xxl={12}
                className="d-flex justify-content-center headline_highlights position-relative font-poppins text-center"
              >
                <div>
                  <h1>Herzlich willkommen bei BraunBärchen</h1>
                  <p style={{ fontSize: '1.25rem' }}>
                    deinem Onlineshop für nachhaltige Secondhand-Kindermode.
                  </p>
                </div>
              </Col>

              <Col className="py-5 w-100 d-flex justify-content-center">
                <Container>
                  <div className="p-3">
                    <p align="justify" style={{ hyphens: 'auto' }}>
                      Ein Herzensprojekt von Eltern für Eltern: Braunbärchen
                      macht Secondhand zu einem Erlebnis! Schluss mit schlechter
                      Qualität, hohen Versandkosten und stundenlangem Suchen
                      nach schöner Secondhand-Kleidung. Bei uns finden Eltern:
                    </p>
                    <ul>
                      <li>Große Auswahl an einem Ort</li>
                      {/* <li>
                        Kostenloser Versand ab einem Einkaufswert von 50 €
                      </li> */}
                      <li>
                        Einmalige Versandkosten von nur 4,99 €, egal wie groß
                        das Paket ist, oder kostenlose Abholung an einer
                        kontaktlosen Abholstation in Wipperfürth
                      </li>
                      <li>14 Tage kostenfreies Rückgaberecht</li>
                      <li>Geprüfte Qualität und Sicherheit</li>
                      <li>Viele flexible Zahlarten</li>
                    </ul>
                    <p>
                      Dank unserer praktischen Filter findest du schnell, was du
                      suchst. Jeden Freitag kommen neue Stücke dazu - so wird
                      Stöbern nie langweilig!
                    </p>
                  </div>
                </Container>
              </Col>
            </Row>
            {/* <Row className="g-0 py-5">
              <Col className="w-100 d-flex justify-content-center">
                <GreenWeek />
              </Col>
            </Row> */}
            <Row className="g-0 py-5">
              <Col className="w-100 d-flex justify-content-center">
                <NewBees products={products} />
              </Col>
            </Row>
            <Row className="g-0 py-5">
              <Col className="w-100 d-flex justify-content-center">
                <NavToShops />
              </Col>
            </Row>
            <Row className="g-0 py-5">
              <Col className="w-100 d-flex justify-content-center">
                <Reviews />
              </Col>
            </Row>
            <Row className="my-3 g-0 w-100"> </Row>
          </>
        )}
      </Container>
    </div>
  );
}

export default HomeScreen;
