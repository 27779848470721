import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { shopType } from 'utils/types';
import AdminRoute from 'components/AdminRoute';
import HomeScreen from 'views/HomeScreen';
import BrandsInfoScreen from 'views/Admin/BrandsInfoScreen';
import PacklistScreen from 'views/Admin/PacklistScreen';

const ProductScreen = lazy(() => import('../views/ProductScreen'));
const CartScreen = lazy(() => import('../views/CartScreen'));
const SigninScreen = lazy(() => import('../views/Admin/SigninScreen'));
const SignupScreen = lazy(() => import('../views/Admin/SignupScreen'));
const OrderScreen = lazy(() => import('../views/Order/OrderScreen'));
const OrderHistoryScreen = lazy(() =>
  import('../views/Admin/OrderHistoryScreen')
);
const ShopScreen = lazy(() => import('../views/ShopScreen'));
const FactorScreen = lazy(() => import('../views/Admin/FactorScreen'));
const ReportScreen = lazy(() => import('../views/Admin/ReportScreen'));
const DashboardScreen = lazy(() => import('../views/Admin/DashboardScreen'));
const AboutUsScreen = lazy(() => import('../views/Info/AboutUsScreen'));
const NewProductScreen = lazy(() => import('../views/Admin/NewProductScreen'));
const ProductListScreen = lazy(() =>
  import('../views/Admin/ProductListScreen')
);
const ProductEditScreen = lazy(() =>
  import('../views/Admin/ProductEditScreen')
);
const WebApp = lazy(() => import('views/Info/WebApp'));
const PlaceOrderUserDataScreen = lazy(() =>
  import('views/Order/PlaceOrderUserDataScreen')
);
const PlaceOrderDeliveryScreen = lazy(() =>
  import('views/Order/PlaceOrderDeliveryScreen')
);
const PlaceOrderPaymentMethodScreen = lazy(() =>
  import('views/Order/PlaceOrderPaymentmethodScreen')
);
const PlaceOrderOrderSummaryScreen = lazy(() =>
  import('views/Order/PlaceOrderOrderSummaryScreen')
);
const ImpressumScreen = lazy(() => import('views/LegalTexts/ImpressumScreen'));
const PayAndDeliveryScreen = lazy(() =>
  import('views/LegalTexts/PayAndDeliveryScreen')
);
const RevocationScreen = lazy(() =>
  import('views/LegalTexts/RevocationScreen')
);
const DataProtectionScreen = lazy(() =>
  import('views/LegalTexts/DataProtectionScreen')
);
const AGBScreen = lazy(() => import('views/LegalTexts/AGBScreen'));
const BaggerScreen = lazy(() => import('views/BaggerScreen'));
const SaleScreen = lazy(() => import('views/Admin/SaleScreen'));
const PickupStationScreen = lazy(() =>
  import('views/Info/PickupstationScreen')
);
const FAQScreen = lazy(() => import('views/Info/FAQScreen'));
const TrackingDashboardScreen = lazy(() =>
  import('views/Admin/TrackingDashboard')
);
const ShopProducts = lazy(() => import('views/ShopProducts'));
const PlaceOrderScreen = lazy(() => import('views/Order/PlaceOrderScreen'));
const SellerScreen = lazy(() => import('views/Admin/SellerScreen'));
const SellerDetailScreen = lazy(() => import('views/Admin/SellerDetailScreen'));
const BecomeSellerScreen = lazy(() => import('views/Info/BecomeSellerScreen'));
const OrderSuccessfulScreen = lazy(() =>
  import('views/Order/OrderSuccessfulScreen')
);

export default function AppRoutes() {
  return (
    <Routes>
      <Route
        path="/shop/kleidung"
        element={
          <ShopScreen key={shopType.Kleidung} shopType={shopType.Kleidung} />
        }
      >
        <Route index element={<ShopProducts />} />
        <Route path="product/:itemNumber" element={<ProductScreen />} />
      </Route>
      <Route path="/shop" element={<Navigate replace to="/shop/kleidung" />} />
      <Route
        path="/shop/schlafsack"
        element={
          <ShopScreen
            key={shopType.Schlafsack}
            shopType={shopType.Schlafsack}
          />
        }
      >
        <Route index element={<ShopProducts />} />
        <Route path="product/:itemNumber" element={<ProductScreen />} />
      </Route>
      <Route
        path="/shop/tonies"
        /* element={
    <ShopScreen
      key={shopType.Tonies}
      shopType={shopType.Tonies}
    />
  } */
        element={<BaggerScreen />}
      />
      <Route
        path="/mieten"
        /* element={
    <ShopScreen
      key={shopType.Tonies}
      shopType={shopType.Tonies}
    />
  } */
        element={<BaggerScreen />}
      />
      <Route
        path="/shop/accessoires"
        element={
          <ShopScreen
            key={shopType.Accessoires}
            shopType={shopType.Accessoires}
          />
        }
      >
        <Route index element={<ShopProducts />} />
        <Route path="product/:itemNumber" element={<ProductScreen />} />
      </Route>
      <Route path="/product/:itemNumber" element={<ProductScreen />} />
      <Route path="/warenkorb" element={<CartScreen />} />
      <Route path="/admin/signin" element={<SigninScreen />} />
      <Route path="/admin/signup" element={<SignupScreen />} />
      <Route path="/ueber-uns" element={<AboutUsScreen />} />
      <Route path="/webapp" element={<WebApp />} />
      <Route path="/impressum" element={<ImpressumScreen />} />
      <Route path="/agb" element={<AGBScreen />} />
      <Route path="/dsgvo" element={<DataProtectionScreen />} />
      <Route path="/widerruf" element={<RevocationScreen />} />
      <Route path="/faqs" element={<FAQScreen />} />
      <Route path="/abholstation" element={<PickupStationScreen />} />
      <Route path="/zahlung-versand" element={<PayAndDeliveryScreen />} />

      <Route path="/bestellung" element={<PlaceOrderScreen />}>
        <Route index element={<Navigate to="/" replace />} />
        <Route path="benutzerdaten" element={<PlaceOrderUserDataScreen />} />
        <Route path="lieferung" element={<PlaceOrderDeliveryScreen />} />
        <Route
          path="bezahlmethode"
          element={<PlaceOrderPaymentMethodScreen />}
        />
        <Route path="uebersicht" element={<PlaceOrderOrderSummaryScreen />} />
      </Route>
      <Route
        path="/bestellung/erfolgreich"
        element={<OrderSuccessfulScreen />}
      />
      <Route path="/bestellung/:id" element={<OrderScreen />} />
      <Route path="/order/:id" element={<OrderScreen />} />
      <Route path="/verkaufen" element={<BecomeSellerScreen />} />
      {/* <Route
      path="/green-week"
      element={<GreenWeekLandingPage />}
    /> */}
      <Route path="/shop/*" element={<></>} />
      <Route path="/" element={<HomeScreen />} />
      <Route element={<AdminRoute />}>
        <Route path="/admin/dashboard" element={<DashboardScreen />} />
        <Route path="/admin/tracking" element={<TrackingDashboardScreen />} />
        <Route path="/admin/prices" element={<FactorScreen />} />
        <Route
          path="/admin/neuekleidung"
          element={<NewProductScreen type={shopType.Kleidung} />}
        />
        <Route
          path="/admin/neuerschlafsack"
          element={<NewProductScreen type={shopType.Schlafsack} />}
        />
        <Route
          path="/admin/neuertonie"
          element={<NewProductScreen type={shopType.Tonies} />}
        />
        <Route
          path="/admin/neuesaccessoires"
          element={<NewProductScreen type={shopType.Accessoires} />}
        />
        <Route path="/admin/products" element={<ProductListScreen />} />
        <Route path="/admin/product/:id" element={<ProductEditScreen />} />
        <Route path="/admin/orderhistory" element={<OrderHistoryScreen />} />
        <Route path="/admin/reports" element={<ReportScreen />} />
        <Route path="/admin/sale" element={<SaleScreen />} />
        <Route path="/admin/sellerlist" element={<SellerScreen />} />
        <Route
          path="/admin/sellerlist/:sellerNumber"
          element={<SellerDetailScreen />}
        />
        <Route path="/admin/brandsinfo" element={<BrandsInfoScreen />} />
        <Route path="/admin/packliste" element={<PacklistScreen />} />
      </Route>
      <Route path="*" element={<HomeScreen />} />
    </Routes>
  );
}
