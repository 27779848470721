import { useEffect, Suspense } from 'react';
import { ScrollRestoration, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useContext, useState } from 'react';
import { Store } from './Store';
import { isBrowser } from 'react-device-detect';
import * as serviceWorker from './serviceWorkerRegistration';
import { Helmet } from 'react-helmet-async';
import { ErrorBoundary } from 'react-error-boundary';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faRectangleXmark } from '@fortawesome/free-solid-svg-icons/faRectangleXmark';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons/faCircleInfo';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons/faCartPlus';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faSliders } from '@fortawesome/free-solid-svg-icons/faSliders';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons/faCartShopping';
import { faTree } from '@fortawesome/free-solid-svg-icons/faTree';
import LoadingBox from 'components/LoadingBox';
import Footer from 'components/Layout/Footer';
import Header from 'components/Layout/Header';
import AppRoutes from 'components/Routes';
import {
  cookiesAccepted,
  cookiesDenied,
  loadCookiesDefault,
} from 'utils/utils';
import { Modal } from 'react-bootstrap';

import ImgKrankheitsHinweis from 'assets/images/hinweis-krankheit.webp';

library.add(
  faRectangleXmark,
  faCircle,
  faXmark,
  faChevronLeft,
  faTrash,
  faArrowLeft,
  faArrowRight,
  faArrowDown,
  faBars,
  faAngleUp,
  faAngleDown,
  faCircleInfo,
  faCartPlus,
  faSliders,
  faSpinner,
  faFilePdf,
  faCartShopping,
  faCheck,
  faChevronRight,
  faTree
);

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <Container>
      <Row className="mt-5 mb-3">
        <Col className="d-flex justify-content-center">
          <strong>Oh da ist leider etwas schief gelaufen :(</strong>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <div className="btn_action" onClick={resetErrorBoundary}>
            Versuche es erneut
          </div>
        </Col>
      </Row>
    </Container>
  );
}

function App() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { id } = state;

  useEffect(() => {
    if (id === '') {
      ctxDispatch({ type: 'SET_UUID' });
    }
    loadCookiesDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Deny ga4 until cookies accepted

  // Urlaubs Modal
  const [showKrankheitsModal, setShowKrankheitsModal] = useState(false);

  useEffect(() => {
    const OpenKrankheitsModal = () => {
      const modalShown = sessionStorage.getItem('hinweismodal');
      // const today = new Date().toISOString().split('T')[0];
      if (!modalShown) {
        setShowKrankheitsModal(true);
        sessionStorage.setItem('hinweismodal', true);
      }
    };

    const timer = setTimeout(() => {
      if (new Date() < new Date('2025-03-24T00:00:00')) {
        OpenKrankheitsModal();
      }
    }, 5000);

    // clear timer if the component unmount
    return () => clearTimeout(timer);
  }, []);

  const embeddings = [
    'Google Ads Conversion Tracking',
    'Google Analytics 4',
    'Google Maps',
  ];

  const [loadCCMScript, setLoadCCMScript] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadCCMScript(true);
    }, 3000);

    // clear timer if the component unmount
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const onCookieAccept = () => {
      embeddings.forEach((cookie) =>
        window.CCM?.acceptedEmbeddings.some((e) => e.name === cookie)
          ? cookiesAccepted({ cookie, ctxDispatch })
          : cookiesDenied({ cookie, ctxDispatch })
      );
    };

    window.addEventListener('ccm19WidgetClosed', onCookieAccept);

    return () =>
      window.removeEventListener('ccm19WidgetClosed', onCookieAccept);
  }, []);

  useEffect(() => {
    const onCookieAccept = ({ detail }) => {
      cookiesAccepted({ cookie: detail.name, ctxDispatch });
    };
    window.addEventListener('ccm19EmbeddingAccepted', onCookieAccept);

    return () =>
      window.removeEventListener('ccm19EmbeddingAccepted', onCookieAccept);
  }, []);

  // const [updateAvailable, setUpdateAvailable] = useState(false);
  // const [serviceWorkerRegistration, setServiceWorkerRegistration] =
  //   useState(null);

  serviceWorker.register({
    onUpdate: (reg) => {
      // setServiceWorkerRegistration(reg);
      // setUpdateAvailable(true);
      updateServiceWorker(reg);
    },
  });

  const updateServiceWorker = (serviceWorkerRegistration) => {
    try {
      const registrationWaiting = serviceWorkerRegistration.waiting;
      if (registrationWaiting) {
        registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
        registrationWaiting.addEventListener('statechange', (e) => {
          if (e.target.state === 'activated') {
            window.location.reload();
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="body" id="bodydiv">
        <Helmet>
          {loadCCMScript && (
            <script
              async
              defer
              src="https://consenttool.haendlerbund.de/app.js?apiKey=4d62e5cbffce1de5086fbb438f0e9754fa5ff8df8bc3d392&amp;domain=0c61d43"
              referrerpolicy="origin"
            ></script>
          )}
        </Helmet>
        {/* <Container fluid="xl" className="g-0"> */}

        {/* Urlaubsmodal */}
        {/* <Modal
          show={showUrlaubsModal}
          onHide={() => setShowUrlaubModal(false)}
          centered
          style={{ background: 'transparent' }}
        >
          <div
            style={{
              backgroundImage: `radial-gradient(
      circle,
      rgba(0, 0, 0, 0.3) 25%,
      rgba(24, 24, 24, 0.5) 75%
    ), url(${UrlaubImg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              color: 'white',
              textShadow: '2px 2px 4px #000000',
              borderRadius: '0.5rem',
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ color: 'white' }}>
                Urlaubshinweis
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                style={{ color: 'white', textShadow: '2px 2px 4px #000000' }}
              >
                <p>Liebe Kundinnen und Kunden,</p>
                <p>
                 
                  wir sind vom 30. August bis 10. September im Urlaub.
                  Bestellungen, die bis Donnerstag den 29. August eingehen,
                  werden noch bearbeitet. Ab dem 11. September sind wir wieder
                  für euch da und bearbeiten eure Bestellungen.
                </p>
                <p>Vielen Dank für euer Verständnis!</p>
                <p>Eure BraunBärchen</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div
                className="btn_action"
                style={{ textShadow: 'none' }}
                onClick={() => setShowUrlaubModal(false)}
              >
                Verstanden
              </div>
            </Modal.Footer>
          </div>
        </Modal> */}
        <Modal
          show={showKrankheitsModal}
          onHide={() => setShowKrankheitsModal(false)}
          centered
          style={{ background: 'transparent' }}
        >
          <div
            style={{
              backgroundImage: `radial-gradient(
      circle,
      rgba(0, 0, 0, 0.3) 25%,
      rgba(24, 24, 24, 0.5) 75%
    ), url(${ImgKrankheitsHinweis})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              color: 'white',
              textShadow: '2px 2px 4px #000000',
              borderRadius: '0.5rem',
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ color: 'white' }}>Hinweis</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                style={{ color: 'white', textShadow: '2px 2px 4px #000000' }}
              >
                <p>Liebe Kundinnen und Kunden,</p>
                <p>
                  aufgrund von Krankheit kommt es aktuell zu
                  Lieferverzögerungen.
                </p>
                <p>Vielen Dank für euer Verständnis!</p>
                <p>Eure BraunBärchen</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div
                className="btn_action"
                style={{ textShadow: 'none' }}
                onClick={() => setShowKrankheitsModal(false)}
              >
                Verstanden
              </div>
            </Modal.Footer>
          </div>
        </Modal>
        <Header />
        <main>
          <div
            className="wrapper"
            id="wrapper"
            style={{
              paddingTop: isBrowser ? '141px' : '96px',
            }}
          >
            {/* <Container
              fluid="2xl"
              className="main-container container-fluid"
              id="mainContainer"
            > */}
            <div className="pt-3">
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Suspense
                  fallback={
                    <Row className="d-flex justify-content-center">
                      <div style={{ position: 'fixed' }}>
                        <LoadingBox />
                      </div>
                    </Row>
                  }
                >
                  <AppRoutes />
                </Suspense>
              </ErrorBoundary>
            </div>
            {/* </Container> */}
          </div>
        </main>
        <Footer />
      </div>
      <ScrollRestoration />
    </>
  );
}

export default App;
