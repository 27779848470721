import axios from 'axios';
import LoadingBox from 'components/LoadingBox';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Store } from 'Store';

export default function PacklistScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;

  const navigate = useNavigate();

  const [orders, setOrders] = useState([]);
  const [groupedProducts, setGroupedProducts] = useState({});
  const [selectedOrders, setSelectedOrders] = useState({});
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const fetchOrders = async () => {
      const { data: unpackedOrders } = await axios.get('/api/orders/unpacked', {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });

      let orderProducts = [];
      const orderSelection = {};

      for (const order of unpackedOrders) {
        orderSelection[order.orderNumber] = true; // Standardmäßig alle Bestellungen aktiviert
        orderProducts = [
          ...orderProducts,
          ...order.orderItems.filter((p) => !order.packedItems.includes(p._id)),
        ];
      }

      const grouped = {};

      orderProducts.forEach((product) => {
        const storageLocation = product.adminInfo?.storageLocation
          ? product.sellerNumber + '.' + product.adminInfo?.storageLocation
          : 'Keine Kiste';

        if (!grouped[storageLocation]) {
          grouped[storageLocation] = [];
        }

        grouped[storageLocation].push(product);
      });

      if (grouped['Keine Kiste']) {
        grouped['Keine Kiste'].sort((a, b) => {
          if (a.size !== b.size) {
            return a.size.localeCompare(b.size, undefined, { numeric: true });
          }
          return a.category.localeCompare(b.category);
        });
      }

      const sortedGrouped = Object.keys(grouped)
        .sort((a, b) => {
          if (a === 'Keine Kiste') return -1;
          if (b === 'Keine Kiste') return 1;
          return a.localeCompare(b, undefined, { numeric: true });
        })
        .reduce((acc, key) => {
          acc[key] = grouped[key];
          return acc;
        }, {});

      setGroupedProducts(sortedGrouped);
      setOrders(unpackedOrders);
      setSelectedOrders(orderSelection);
    };

    const savedState = JSON.parse(sessionStorage.getItem('packlistState'));
    if (
      savedState &&
      Object.keys(savedState.groupedProducts).length > 0 &&
      savedState.orders.length > 0
    ) {
      setOrders(savedState.orders);
      setGroupedProducts(savedState.groupedProducts);
      setSelectedOrders(savedState.selectedOrders);
      setScrollPosition(savedState.scrollPosition || 0);
    } else {
      fetchOrders();
    }
  }, []);

  const reloadPage = () => {
    sessionStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    if (orders.length > 0) {
      sessionStorage.setItem(
        'packlistState',
        JSON.stringify({
          orders,
          groupedProducts,
          selectedOrders,
          scrollPosition: window.scrollY,
        })
      );
    }
  }, [orders, groupedProducts, selectedOrders, scrollPosition]);

  useEffect(() => {
    window.scrollTo(0, scrollPosition);
  }, [scrollPosition]);

  const toggleOrderSelection = (orderNumber) => {
    setSelectedOrders((prev) => ({
      ...prev,
      [orderNumber]: !prev[orderNumber],
    }));
  };

  const setProductPacked = async (orderId, productId, isPacked) => {
    if (isPacked) {
      await axios.post(
        `/api/orders/set-product-packed`,
        { orderId, productId },
        {
          headers: { authorization: `Bearer ${userInfo.token}` },
        }
      );
    } else {
      await axios.post(
        `/api/orders/unset-product-packed`,
        { orderId, productId },
        {
          headers: { authorization: `Bearer ${userInfo.token}` },
        }
      );
    }

    setGroupedProducts((prev) => {
      const updated = { ...prev };
      for (const key in updated) {
        updated[key] = updated[key].map((p) =>
          p._id === productId ? { ...p, isPacked: !p.isPacked } : p
        );
      }
      return updated;
    });
  };

  return (
    <Container className="mb-5">
      <h1>Packliste</h1>
      <Button onClick={reloadPage} className="mb-3">
        Neu laden
      </Button>
      {orders.length > 0 ? (
        <>
          <Row>
            {orders.map((order) => (
              <Col xs={6} key={order._id}>
                <Form.Check
                  type="checkbox"
                  label={order.orderNumber}
                  checked={selectedOrders[order.orderNumber]}
                  onChange={() => toggleOrderSelection(order.orderNumber)}
                />
              </Col>
            ))}
          </Row>
          <div>
            {Object.keys(groupedProducts).map((g) => (
              <div key={g}>
                {groupedProducts[g].filter(
                  (product) => selectedOrders[product.adminInfo?.orderNumber]
                ).length > 0 && (
                  <Row className="mt-3">
                    <strong
                      style={{
                        textDecoration: 'underline',
                        fontSize: '1.5rem',
                      }}
                    >
                      {g}
                    </strong>
                    {groupedProducts[g]
                      .filter(
                        (product) =>
                          selectedOrders[product.adminInfo?.orderNumber]
                      )
                      .map((product) => (
                        <Col
                          xs={6}
                          md={3}
                          key={product._id}
                          className="d-flex flex-column align-items-center"
                        >
                          <Image
                            height={250}
                            src={product.image}
                            className="p-2"
                            onClick={() => {
                              setScrollPosition(window.scrollY);
                              sessionStorage.setItem(
                                'packlistState',
                                JSON.stringify({
                                  orders,
                                  groupedProducts,
                                  selectedOrders,
                                  scrollPosition: window.scrollY,
                                })
                              );
                              navigate(`/product/${product._id}`);
                            }}
                            style={{ opacity: product.isPacked ? 0.2 : 1 }}
                          />
                          <div>
                            {product.brand} - {product.size}
                          </div>
                          <Button
                            className="btn_action"
                            style={{
                              minWidth: 'auto',
                              background: product.isPacked ? 'transparent' : '',
                              color: product.isPacked ? 'black' : '',
                            }}
                            onClick={() =>
                              setProductPacked(
                                orders.find(
                                  (o) =>
                                    o.orderNumber ===
                                    product.adminInfo.orderNumber
                                )._id,
                                product._id,
                                !product.isPacked
                              )
                            }
                          >
                            {product.isPacked ? 'Gepackt' : 'Nicht Gepackt'}
                          </Button>
                        </Col>
                      ))}
                  </Row>
                )}
              </div>
            ))}
          </div>
        </>
      ) : (
        <LoadingBox />
      )}
    </Container>
  );
}
